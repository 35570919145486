.has-background-black {
  a[data-action] {
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }

  .collapsible-body {
    
  }
}