.map__ {
  &legend-entry {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      text-shadow: -0px -0px 0 #ccc, 0px -0px 0 #ccc, -0px 0px 0 #ccc,
        1px 1px 0 #ccc;
      transition: all 0.2s;
    }
  }

  &legend-header {
    font-weight: bold;
  }

  &legend {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    background: #ffffffad;
    padding: 1.5em;
  }
}

@media screen and (max-width: 768px) {
  .map__legend {
    margin-left: 0 !important;
    margin-top: 0 !important;
    padding: 1em;
    width: 150px;
    letter-spacing: 0.5px;
  }
}

.section-map {
  .popup__line-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 0.3rem;
  }
  .leaflet-popup-content-wrapper {
    background-color: $beige;
    border: 1px solid black;
    color: #373535;
  }

  .tag.is-black {
    background-color: #2b2b2a;
  }
  .leaflet-popup-tip {
    display: none;
  }

  .leaflet-container a.leaflet-popup-close-button {
    display: none;
  }
  .popup__link {
    color: inherit;
    text-decoration: underline;
    display: flex;
    align-items: center;
  }

  .tag:not(body) {
    font-size: 0.8rem;
  }
}

.leaflet-control-attribution {
  letter-spacing: 0;
}
