$beige: #f8eee8;
$beige-transparent: #f8eee895;
$light-beige: #fbf5f1;
$pink: #d9aab8;
$red: #cc503f;
$mustard: #e5bb5b;
$mustard-dark: #93865f;
$purple: #9fa5d9;
$light-blue: #d4e2f7;
$navy: #454775;
$black: #000000;

// Bulma overrides
$title-color: $black;
$subtitle-color: $black;

@import '../node_modules/bulma/sass/utilities/initial-variables';
@import '../node_modules/bulma/bulma.sass';
@import '../node_modules/@creativebulma/bulma-collapsible/dist/css/bulma-collapsible.min.css';

@import './collapsible.scss';
// @import './arrow.scss';
@import './map.scss';

.mt-7 {
  margin-top: 6em;
}

.mt-8 {
  margin-top: 8em;
}

.mt-9 {
  margin-top: 12em;
}

a {
  color: $black;
}

a:hover {
  text-decoration: underline;
  text-underline-offset: 3px;
}

a.unstyled {
  color: $black;
}

a.unstyled:hover {
  cursor: default;
  text-decoration: none;
}

button.unstyled:hover {
  cursor: pointer;
}

.footer__logo {
  max-width: 4em;
  position: absolute;
  right: 70px;
  bottom: 50px;
}

.section-content {
  max-width: 1050px;
  margin: auto;
}

.section-content-slim {
  max-width: 750px;
  margin: auto;
}

.section-map {
  height: 70vh;
  min-height: 500px;
}

.has-text-weight-extrabold {
  font-weight: 900;
}

#map-container {
  height: 100%;
}

p {
  margin-bottom: 1em;
}

body {
  font-family: 'Noto Sans', sans-serif;
  letter-spacing: 1px;
  background-color: $light-beige;
}

.is-color-black {
  color: $black;
}

.is-color-beige {
  color: $beige;
}

.is-white-fill path {
  fill: $white;
}

.has-extra-letter-spacing {
  letter-spacing: 0.25em;
}

.has-no-letter-spacing {
  letter-spacing: 0;
}

.has-half-letter-spacing {
  letter-spacing: 0.5px;
}

.top-bar__title {
  word-spacing: 0.5em;
}

.hero-title {
  font-size: 11rem !important;
  width: 100%;
  line-height: 12rem;
  font-weight: 600;
}

// Desktop
@media screen and (max-width: 1215px) {
  .hero-title {
    font-size: 9rem !important;
  }
}

// Tablet
@media screen and (max-width: 1023px) {
  .hero-title {
    font-size: 7rem !important;
  }

  .hero-intro {
    // height: 80vh;
  }
}

// Mobile
@media screen and (max-width: 768px) {
  .has-no-letter-spacing-mobile {
    letter-spacing: 0;
  }

  .top-bar__title {
    word-spacing: initial;
  }

  .section-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .hero-title {
    font-size: 3rem !important;
    line-height: 3rem;
  }

  .hero-custom-body {
    // FIXME change to vertically centered
    padding-top: 4rem !important;
  }
}

.main {
  background: url(../img/map-bg.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: $beige;
  height: calc(100vh - 115px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 500px;
}

.circle {
  border-radius: 50%;
  width: 3em;
  height: 3em;
  margin: auto;
}

.circle--sm {
  display: flex;
  width: 6em;
  height: 6em;
  text-align: center;
  vertical-align: middle;
}

.circle--md {
  display: flex;
  width: 10em;
  height: 10em;
  text-align: center;
  vertical-align: middle;
}

.circle--big {
  display: flex;
  width: 13em;
  height: 13em;
  text-align: center;
  vertical-align: middle;
}

.has-font-noto-sans {
  font-family: 'Noto Sans', sans-serif;
}

.has-font-inter {
  font-family: Inter, sans-serif;
}

.has-font-nanum-myeongjo {
  font-family: 'Nanum Myeongjo', serif;
}

.has-font-lato {
  font-family: 'Lato', sans-serif;
}

.container-quote {
}

.container-quote__quote {
}

.has-background-beige {
  background-color: $beige;
}

.has-background-beige-light {
  background-color: $light-beige;
}

.has-background-pink {
  background-color: $pink;
  color: $black;
}

.has-background-red {
  background-color: $red;
  color: $black;
}

.has-background-mustard {
  background-color: $mustard;
  color: $black;
}

.has-background-mustard-dark {
  background-color: $mustard-dark;
  color: #ffffff;
}

.has-background-light-blue {
  background-color: $light-blue;
}

.has-background-purple {
  background-color: $purple;
  color: $black;
}

.has-background-light-blue {
  background-color: $light-blue;
}

.has-background-black {
  background-color: $black;
  color: #ffffff;
}

.has-background-navy {
  background-color: $navy;
  color: $beige;
  a {
    color: $beige;
  }
}

.has-colour-white {
  color: #ffffff;
}

h1 {
  font-family: 'Nanum Myeongjo', serif;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-grow: 8;
  font-size: 1.5em;
  color: black;
  font-weight: bold;
  // font-size: 36px;
  // letter-spacing: 32px;
  // line-height: 64px;
}

p {
  flex-grow: 1;
}

footer {
  background-color: $beige;
}

.input-wrapper {
  // width: 0px;
  display: inline-block;
  margin-bottom: 24px;
  display: inline-flex;
  align-items: center;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: italic;
  text-align: center;
  font-size: 0.75em;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  text-align: center;
  font-size: 0.75em;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-style: italic;
  text-align: center;
  font-size: 0.75em;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  text-align: center;
  font-size: 0.75em;
}

/* https://codepen.io/lewisvrobinson/pen/EyZwjR */

.material-input {
  background: none;
  padding: 10px 10px 10px 5px;
  width: 250px;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid black;
  // height: 20px;
}

.material-input:focus {
  outline: none;
}

#mc_embed_signup {
  flex-grow: 1;
}

.icon {
  height: 30px;
  width: 30px;
}

.is-reversed {
  transform: scalex(-1);
}

.logo {
  width: 100px;
  padding-bottom: 1px;

  &:hover {
    border-bottom: 1px solid #363636;
    padding-bottom: 0px;
  }
}

button {
  all: unset;
  height: 42px;
  // width: 350px;
}

button:focus {
  outline: orange 5px auto;
}

button .icon {
  float: left;
}

.footer-content-wrapper {
  display: inline-flex;
  align-items: center;
  width: 350px;
}

.footer-content-wrapper .logo {
  flex-grow: 1;
}

@media (max-width: 480px) {
  h1 {
    letter-spacing: 0.1em;
    font-size: 32px;
    line-height: 48px;
  }

  .input-wrapper {
    width: 280px;
  }

  button {
    width: 30px;
  }

  .footer-content-wrapper {
    width: 280px;
  }

  footer {
    padding-bottom: 30px;
  }

  .main {
    height: calc(100vh - 95px);
  }
}

.is-overflowed-arrow {
  overflow: initial;
  margin-left: -45%;
  width: 90px;
}

.is-overflowed-arrow--md {
  width: 175px;
}

.gallery-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 260px;
  background-size: cover;

  &.gallery-item:hover .item-text--content {
    text-underline-offset: 3px;
    text-decoration: underline;
  }

  &.gallery-item.unstyled:hover .item-text--content {
    cursor: default;
    text-decoration: none;
  }
  &.gallery-item.unstyled:hover {
    cursor: default;
  }
}

.item-text {
  position: absolute;
  background-color: $beige-transparent;
  width: 100%;
}

.hero-background {
  background: no-repeat center center fixed;
  background-size: cover;
}

.tag.tag-outline.is-black {
  background-color: transparent;
  color: black;
  border: 1px solid black;
  border-radius: 12px;
  margin-bottom: 0.25em;
}

.hide-text-decoration {
  margin-right: -5px;
}

.tag.tag-outline.is-black {
  &.has-background-beige {
    background-color: rgba($beige, 0.45) !important;
  }

  &.has-background-beige-light {
    background-color: rgba($light-beige, 0.45) !important;
  }

  &.has-background-pink {
    background-color: rgba($pink, 0.45) !important;
  }

  &.has-background-red {
    background-color: rgba($red, 0.45) !important;
  }

  &.has-background-mustard {
    background-color: rgba($mustard, 0.45) !important;
  }

  &.has-background-mustard-dark {
    background-color: rgba($mustard-dark, 0.45) !important;
  }

  &.has-background-light-blue {
    background-color: rgba($light-blue, 0.45) !important;
  }

  &.has-background-purple {
    background-color: rgba($purple, 0.45) !important;
  }

  &.has-background-light-blue {
    background-color: rgba($light-blue, 0.45) !important;
  }

  &.has-background-black {
    background-color: rgba($black, 0.45) !important;
  }

  &.has-background-navy {
    background-color: rgba($navy, 0.45) !important;
    color: $white;
  }
}

// inline images on text
.img--max-h-550 {
  max-height: 550px;
}

// navbar

.navbar-burger {
  position: absolute;
  right: 0;
}

@media screen and (max-width: 768px) {
  .py-0-mobile-only {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 1024px) {
  .float-left-icon {
    position: absolute;
    left: 22%;
  }
}
@media screen and (min-width: 1408px) {
  .float-left-icon {
    position: absolute;
    left: 30%;
  }
}

.mc-modal-bg {
  opacity: 0.5 !important;
}

// Community member page

#community-member a {
  word-break: break-all;
}

// Cookie manager

@import '../node_modules/vanilla-cookieconsent/dist/cookieconsent.css';

.bns-t .title {
  color: inherit;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.2;
}

#cm {
  background: $beige;
}
